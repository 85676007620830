<template>
  <div class="newSegnalazione" style="height:100%;">
    <v-toolbar color="secondary">
        <v-toolbar-title class="white--text">
            Nuova Segnalazione
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small icon @click="$emit('rapportinoDone')" color="white" class="ml-3 mr-1">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
    </v-toolbar>
    <v-card outlined>
        <v-form v-model="formValid" ref="form_data" lazy-formValidation>
            <v-container>
                <v-layout column>
                    <v-text-field
                        autocomplete="off"
                        label="Titolo"
                        v-model="titolo"
                        hide-details="auto"
                        class="mb-4"
                        required
                        :rules="testoRules"
                    ></v-text-field>
                    <v-textarea
                        autocomplete="off"
                        label="Note"
                        v-model="note"
                        outlined
                        auto-grow
                        hide-details="auto"
                        rows="1"
                        class="mb-0"
                    ></v-textarea>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="12" class="py-0">
                          <v-checkbox label="Informa Utente" dense v-model="informaUtente"></v-checkbox>
                        </v-col>
                        <v-col cols="12" offset="0" sm="6" offset-sm="3" class="py-0">
                          <VueUploadMultipleImage 
                            :showEdit="false"
                            :showDelete="true"
                            :showAdd="true"
                            :disabled="false"
                            dragText="Carica Immagini"
                            browseText="Drag & Drop"
                            dropText="Rilascia Qui"
                            accept="image/gif,image/jpeg,image/png,image/bmp,image/jpg,image/*"
                            class="uploaderFix"
                            @upload-success="imageListChanged"
                            @before-remove="removeImage"
                            @edit-image="imageListChanged"
                            :data-images="immagini"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                </v-layout>
            </v-container>
        </v-form>
        <v-card-actions>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4" offset-md="4" sm="12" class="pt-0">
                <v-btn color="primary" width="100%" @click="submit" :disabled="!formValid || loading">Aggiungi</v-btn> 
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import apiAttivita from '@/js/pages/attivita';
import apiSegnalazioni from '@/js/pages/segnalazioni';
import { testoRules, requiredRules } from '@/js/validationRules';
import VueUploadMultipleImage from 'vue-upload-multiple-image';

export default {
  name: 'newSegnalazione',
  components: {
    VueUploadMultipleImage,
  },
  props: ['id_attivita', 'rapporto'],
  data() {
    return {
      token: this.$cookies.get('token'),
      ruolo: this.$store.state.ruolo,
      titolo: '',
      note: '',
      informaUtente: false,
      id_cliente: undefined,
      id_stabilimento: undefined,
      formValid: false,
      testoRules: testoRules,
      requiredRules: requiredRules,
      loading: false,
      array_evacuatori_selected: [],
      imageUploaded: undefined,
      immagini: []
    }
  },
  async created() {
    if(this.rapporto) {
      this.array_evacuatori_selected = [this.rapporto.id_evacuatore];
      let attivita = await apiAttivita.getOne(this.id_attivita);
      if(attivita) {
        this.id_cliente = attivita['id_cliente'];
        this.id_stabilimento = attivita['id_stabilimento'];
      }

      this.note = this.rapporto['note'] || '';
      if(
        this.rapporto.immagini && 
        this.rapporto.immagini.length
      ) {
        for (const immagine of this.rapporto.immagini) {
          let nomeFile = '';
          nomeFile = immagine.substring(immagine.lastIndexOf("/")+1);
          //nomeFile = nomeFile.substring(0, nomeFile.lastIndexOf("_")) + nomeFile.substring(nomeFile.lastIndexOf("."));
          this.immagini.push({
            default: 0,
            highlight: 0,
            path: 'https://app.lacelle.it:5000/'+immagine,
            finalName: immagine,
            name: nomeFile
          });
        }
      }
    }
  },
  methods: {
    async submit(){
      this.loading = true;
      if(
        this.$refs.form_data.validate() &&
        this.formValid
      ){
        let data = {
          titolo: this.titolo,
          informa_utente: this.informaUtente,
          note: this.note,
          da_cliente: false,
          id_cliente: this.id_cliente,
          id_stabilimento: this.id_stabilimento,
          id_evacuatori: this.array_evacuatori_selected,
        };
        let id_segnalazione = await apiSegnalazioni.insertNew(this.token, data);
        if(id_segnalazione) {
          let formData = new FormData();
          for (const immagine of this.immagini) {
            if(immagine.path.substring(0,25) == 'https://app.lacelle.it:5000') formData.append("immagini", new Blob(), immagine.finalName);
            else {
              let finalBlob = await fetch(immagine.path).then(res => res.blob());
              formData.append("immagini", finalBlob, immagine.name);
            }
          }
          apiSegnalazioni.sendFileImmagini(this.token, id_segnalazione, formData);
        }
        this.$emit('rapportinoDone');
      }
      this.loading = false;
    },
    async inviaImmagine() {
      if (this.imageUploaded) {
        let formData = new FormData();
        formData.append("immagine", this.imageUploaded);
        await apiProdotti.uploadImage(this.token, formData, this.id);
        await this.loadData();
        this.imageUploaded = undefined;
      }
    },
    imageListChanged(formData, index, fileList) {
      this.immagini = fileList;
    },
    async removeImage(index, done, fileList) {
      var r = confirm("Rimuovere l'immagine?");
      if (r == true) {
        await done();
        this.immagini = fileList;
      }
    },
  },
}
</script>
